import "../styles/globals.css";
import "../styles/App.scss";
// import '../styles/trootech.scss';
import "../styles/trootech/trootech-normal.scss";
import "../styles/trootech/trootech-md-device.scss";
import "../styles/trootech/trootech-ipad.scss";
import "../styles/trootech/trootech-md-min-max.scss";
import "../styles/trootech/trootech-mobile.scss";
// import '../styles/latest.scss';
import "../styles/yash.scss"; /* carreer page, news page, agreement page, ccase studies page, modal  */
import "../styles/contect-us.scss";
import "../styles/careers-page.scss";
import "../layout/common/footer-final.scss";
import "../layout/common/header-final.scss";
import "../layout/common/trootech-v2-slider.scss";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "bootstrap/dist/css/bootstrap.min.css";

import "../components/request-proposal/request.scss";

// new home
// import "../components/home-new/enterprices-experiance/enterprices-experiance.scss";
// import "../components/home-new/brand-section/brand.scss";
// import "../components/home-new/industry/industry-new.scss";
// import "../components/home-new/studies/studies.scss";
// import "../components/home-new/next-project/nextproject.scss";
// import "../components/home-new/process-section/process.scss";
// import "../components/home-new/customer-section/customer.scss";
// import "../components/home-new/developer-section/developer.scss";

// import "../components/home-new/award-section/award.scss";
// import "../components/home-new/feature/feature.scss";
import "../components/home-new/faq/faq.scss";
import "../components/home-new/contact-section/contact.scss";
import "../components/contact-us-new/global-section/global.scss";
import "../styles/color.scss";
// import "../components/home-new/banner/banner.scss";

// new home end

// import "../components/contact-us-new/banner-contact/banner.scss";
// import "../components/contact-us-new/case-studies/casestudies.scss";

// import "../components/technologies-new/slider-section/slider.scss";
// import "../components/technologies-new/explore-section/explore.scss";

// import '../components/case-studies-detail/banner-contact/banner.scss';
// import '../components/case-studies-detail/case-studies/casestudies.scss';
// import '../components/case-studies-detail/related-case-studies/related-case-studies.scss';
// import '../components/case-studies-detail/project-overview/project-overview.scss';

import "../styles/main-style.scss";
import "../styles/use-case.scss";
import "react-toastify/dist/ReactToastify.css";

import store from "../store/store";
import Script from "next/script";
import App from "next/app";
import { Provider } from "react-redux";
import React, { Suspense, createContext } from "react";
import { ToastContainer } from "react-toastify";
import dynamic from "next/dynamic";

const FormLoader = dynamic(() => import("../components/common/FormLoader"));
const Analytics = dynamic(() => import("../components/gtm/Analytics"));

// import * as Sentry from '@sentry/nextjs';

// Sentry.init({
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//   // Additional configuration options
// });

// const SnowFlake = dynamic(() => import('../components/common/SnowFlake'), {ssr :false});

export const FormContext = createContext({
  showModal: false,
  showForm: () => console.log("FormContext Value Not Provided"),
  hideForm: () => console.log("FormContext Value Not Provided"),
});

class MyApp extends App {
  // static async getInitialProps ({ Component, ctx }) {
  // 	const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

  // 	//Anything returned here can be access by the client
  // 	return { pageProps: pageProps };
  // }

  render() {
    //Information that was returned  from 'getInitialProps' are stored in the props i.e. pageProps
    const { Component, pageProps } = this.props;
    let getTheme = "light";
    if (typeof window !== "undefined") {
      getTheme = localStorage.getItem("Theme");
    }
    return (
      // <Sentry.ErrorBoundary>
      <Provider store={store}>
        <Suspense>
          <Analytics />
        </Suspense>

        <Script id="show-banner" strategy="afterInteractive">
          {`
							document?.getElementById('form-modal-open')
								? document.body.style.overflow = 'hidden'
								: document.body.style.overflow = 'initial'
						`}
        </Script>
        <Script
          id="clarity-script"
          type="text/javascript"
          strategy="afterInteractive"
        >
          {`
							(function(c,l,a,r,i,t,y){
								c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
								t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
								y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
							})(window, document, "clarity", "script", "gxlypqpyub");
						`}
        </Script>

		<Script id="vtag-ai-js" async src="https://r2.leadsy.ai/tag.js" data-pid="1kxlgWjrLWXekvDa1" data-version="062024"></Script>

        <FormLoader />
        {/* <SnowFlake /> */}
        <Component {...pageProps} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={getTheme}
        />
      </Provider>
      // </Sentry.ErrorBoundary>
    );
  }
}

export default MyApp;
